<template>
  <div v-loading="loading">
    <div class="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
      <div class="mt-10 sm:mt-0">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-left text-lg font-medium leading-6 text-gray-900">
                Personal Information
              </h3>
            </div>
          </div>
          <div class="mt-5 md:col-span-2 md:mt-0">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :label-position="labelPosition"
              class="mw"
            >
              <div class="overflow-hidden shadow sm:rounded-md">
                <div class="bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-6">
                      <p>
                        {{ createdBy.FullName }} invites you to be part of
                        {{ orgName }}
                      </p>
                      <el-form-item label="Username">
                        <el-input
                          disabled
                          v-model="form.username"
                          autocomplete="user-name"
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="First name" prop="firstName">
                        <el-input
                          v-model="form.firstName"
                          autocomplete="given-name"
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="Last name" prop="lastName">
                        <el-input
                          v-model="form.lastName"
                          autocomplete="last-name"
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></el-input>
                      </el-form-item>

                      <el-form-item label="Password" prop="password">
                        <el-input
                          v-model="form.password"
                          show-password
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="Confirm Password"
                        prop="confirmPassword"
                      >
                        <el-input
                          v-model="form.confirmPassword"
                          show-password
                          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <el-button
                    class="focus:outline-none border-none bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500"
                    type="primary"
                    @click="onSubmit('form')"
                    >Save</el-button
                  >
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    let reg = /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,12}$/;

    var validateNewPwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(
          new Error(
            "The password should be combination of numbers, letters or characters!"
          )
        );
      } else {
        callback();
      }
    };
    var validateComfirmPwd = (rule, value, callback) => {
      if (!reg.test(value)) {
        callback(
          new Error(
            "The password should be combination of numbers, letters or characters!"
          )
        );
      } else if (this.form.password !== value) {
        callback(
          new Error(
            "The confirmation password is different from the new password."
          )
        );
      } else {
        callback();
      }
    };

    return {
      loading: false,
      org: {},
      orgName: "",
      labelPosition: "top",
      createdBy: {},
      form: {
        firstName: "",
        lastName: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        firstName: [
          {
            required: true,
            message: "Please input First Name",
            trigger: "blur",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Please input Last Name",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter a new password.",
          },
          {
            min: 6,
            max: 12,
            message: "Length of 6 to 12 characters",
            trigger: "blur",
          },
          { validator: validateNewPwd, trigger: "blur" },
        ],
        confirmPassword: [
          {
            required: true,
            message: "Please enter a confirmation password.",
          },
          {
            min: 6,
            max: 12,
            message: "Length of 6 to 12 characters",
            trigger: "blur",
          },
          { validator: validateComfirmPwd, trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.loading = true;
    const url =
      process.env.VUE_APP_API_LOCATION +
      `Users/OrganizationInvite?InviteId=${this.$route.params.orgId}`;

    axios
      .get(url)
      .then((results) => {
        console.log({ results });
        this.org = results.data.Item;
        this.createdBy = results.data.Item.CreatedBy;

        this.form.username = this.org.Username;
        this.orgName = this.org.Organization.Name;
        this.loading = false;
      })
      .catch(() => {
        this.$notify({
          title: "Warning",
          message: "Did not found any Organization with this ID.",
          type: "warning",
        });
        this.loading = false;
      });
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          const url =
            process.env.VUE_APP_API_LOCATION + `Users/Organization/Onboard`;

          let data = {
            firstName: this.form.firstName,
            lastName: this.form.lastName,
            password: this.form.password,
            organizationUserInviteId: this.$route.params.orgId,
          };

          axios
            .post(url, data)
            .then(() => {
              this.loading = false;
              this.$notify({
                title: "Success",
                message: "User info saved.",
                type: "success",
              });
              this.$router.push({ name: "login" });
            })
            .catch((error) => {
              this.loading = false;
              let message = "Problem while saving!";

              if (error.response.data.Message !== undefined)
                message = error.response.data.Message;
              this.$notify.warning({
                title: "Request",
                message: message,
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.mw {
  max-width: 640px;
}
</style>
